import { type FC, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { AppConstants } from '@givematch/common'
import { Tracking } from '../service/tracking'
import { isProd } from '@lib/services'
import { useQuery } from 'react-query'
import { ShareCodeError, shareDetails } from '~/state/shareDetails'
import { Spinner } from '@lib/components'
import { ShareSocialMediaLinks } from '~/components/ShareSocialMediaLinks'
import { getShareMessage, tempCharityShareMessage } from '~/service/shareMessage'
import { shouldUseTempFeature } from '~/components/FundRaiser/temp-charity-feature/TempCharityFeature'

export const ShareLinksWidget: FC = () => {
  const { shareId } = useParams() as { shareId: string }

  const queryParams = new URLSearchParams(useLocation().search)

  const email = queryParams.get('email')
  const [charityName, setCharityName] = useState('')
  const [charityId, setCharityId] = useState('')
  const [error, setError] = useState('')
  const [landingPage, setLandingPage] = useState('')
  const [fundraiserTitle, setFundraiserTitle] = useState('')
  const shareUrl = `${window.location.origin}?link=${shareId}`

  const { isLoading: shareLoading } = useQuery(['shareDetails', shareId], async () => shareDetails(shareId), {
    onSuccess: (res) => {
      setCharityName(res.charity_name)
      setCharityId(res.charity)
      setLandingPage(res.landing_page)
    },
    onError: (err) => {
      if (!isProd() && shareId === AppConstants.testShareCode) {
        // This is to make sure that the widget integration goes through in staging site
        setCharityName('Test Charity Name')
        return
      }
      if (err instanceof ShareCodeError) {
        setError('The share code appears to be invalid')
      } else {
        setError('Unable to retrieve share details.')
      }
    }
  })
  useEffect(() => {
    if (email) {
      Tracking.identify(email)
    }
    const currentFundraiser = sessionStorage.getItem('current_fundraiser')
    if (currentFundraiser) {
      const parsedData = JSON.parse(currentFundraiser)
      const currentFundraiserTitle = parsedData.fundraiser_name
      setFundraiserTitle(currentFundraiserTitle)
    }
  }, [email])

  const useTemp = shouldUseTempFeature(charityId)

  const shareMessage = useTemp
    ? tempCharityShareMessage(charityName, shareUrl)
    : getShareMessage(landingPage, charityName, fundraiserTitle, shareUrl, charityId)

  return <div className="flex flex-col max-w-xl min-h-screen px-8 py-12 mx-auto font-medium font-poppins text-gm-green-900">
    {shareLoading && <Spinner className="mx-auto" />}

    {!shareLoading && error !== '' && <p className="mt-4 text-center text-gm-red">
      {error}
    </p>
    }

    {!shareLoading && error === '' && <ShareSocialMediaLinks charityID={charityId} shareUrl={shareUrl} shareMessage={shareMessage}/>}

  </div>
}
