import { type FC } from 'react'
import { Button } from '@lib/components'

interface Props {
  onClick: () => void
  noDonation: boolean
}
export const DonationButtonStandalone: FC<Props> = ({ onClick, noDonation }) => {
  return (
    <div
      className="relative w-full sm:w-auto flex"
    >
      <div
        className="absolute top-0 -inset-x-4 sm:inset-x-0 sm:w-full h-full bg-gm-newgreen-50 sm:rounded-lg"
      />
      <div
        className="w-full flex flex-col gap-5 py-5 sm:px-5 z-10"
      >
        <div className="flex flex-col gap-2 items-center">
          <span className="text-gm-green font-bold text-xl">
            YOU GIVE, WE MATCH
          </span>
          <span>GiveMatch doubles your donations</span>
        </div>
        <Button
          className={`w-full rounded-full h-14 ${noDonation ? ' cursor-not-allowed bg-sz-gray-500  active:bg-sz-gray-500 text-gm-black' : ''}`}
          variant={`${noDonation ? 'gm-primary-md' : 'gm-share'}`}
          disabled={noDonation}
          onClick={onClick}
        >
          <span className="items-center text-xl">
            Donate Now
          </span>
        </Button>
      </div>
    </div>
  )
}
