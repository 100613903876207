import { type FC } from 'react'
import { LoginForm } from '@lib/components'
import Header from '~/components/Header'
import { Tracking } from '~/service/tracking'
import { useFundraiserPlan } from '~/hooks/useFundraiserPlan'
import { Platforms } from '@lib/services'

interface Props {
  email?: string
  emailUnEditable?: boolean
}
export const VerifyAccount: FC<Props> = ({ email, emailUnEditable }) => {
  const { fundraiser } = useFundraiserPlan()
  const trackFundraiser = (): void => {
    if (fundraiser.postcode !== '') {
      Tracking.verifyFundraiserAccount(fundraiser)
    }
  }
  return <div className="min-h-screen w-full flex flex-col md:items-center">
    <Header />
    <div className='flex flex-col mx-5 max-w-lg md:w-1/2'>
      <span className='text-2xl font-bold mb-10'>Verify Account</span>
      <LoginForm fontVariant="sz" buttonVariant="sz-primary" email={email} emailUnEditable={emailUnEditable} track={() => trackFundraiser()} platform={Platforms.donor}/>
    </div>
  </div>
}
