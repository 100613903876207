import { type FC } from 'react'
import { Link } from 'react-router-dom'
import { Page } from '@lib/components'
import Footer from '~/components/Footer'
import Header from '~/components/Header'

const FAQs: FC = () => {
  function scrollToSection (id: string): void {
    document.getElementById(id)?.scrollIntoView()
  }

  return <>
    <Header />

    <Page skinny className="px-4 my-8">
      <h1 className="text-4xl font-semibold tracking-tighter text-center text-gm-green">FAQs</h1>
      <div className="mt-8">
        <p>Everything you need to know about Givematch. Jump to:</p>
        <div className="mt-4 font-semibold md:flex">
          <ul className="flex-1 pl-0 my-0 list-none">
            <li className="mt-2 underline cursor-pointer text-gm-green md:mt-0" onClick={() => { scrollToSection('about') }}>About</li>
            <li className="mt-2 underline cursor-pointer text-gm-green md:mt-4" onClick={() => { scrollToSection('donation') }}>Donation process</li>
            <li className="mt-2 underline cursor-pointer text-gm-green md:mt-4" onClick={() => { scrollToSection('fundraiser') }}>Fundraiser</li>
            <li className="mt-2 underline cursor-pointer text-gm-green md:mt-4" onClick={() => { scrollToSection('postDonation') }}>Post donation</li>
          </ul>
          <ul className="flex-1 pl-0 my-0 list-none">
            <li className="mt-2 underline cursor-pointer text-gm-green md:mt-0" onClick={() => { scrollToSection('giftAidTax') }}>GiftAid (UK) &#38; tax-deductibility (USA)</li>
            <li className="mt-2 underline cursor-pointer text-gm-green md:mt-4" onClick={() => { scrollToSection('security') }}>Security</li>
            <li className="mt-2 underline cursor-pointer text-gm-green md:mt-4" onClick={() => { scrollToSection('moreInfo') }}>More Information</li>
          </ul>
        </div>
      </div>

      <div className="mt-8" id="about">
        <h2 className="my-4 text-2xl font-bold text-gm-green">About Givematch</h2>

        <h3 className="mt-8 text-lg font-bold">What is Givematch?</h3>
        <p className="mt-4">Givematch is a match funding platform where your donations can get doubled by philanthropic corporate partners, celebrities, brands, charitable foundations, high net worth donors, and governmental institutions.</p>

        <h3 className="mt-8 text-lg font-bold">How does Givematch work?</h3>
        <p className="mt-4">Globally, over £10 billion GBP is pledged for match funding from corporations including FTSE 100 companies and philanthropic entities. Givematch is a marketplace of philanthropy that enriches the social experience of giving by connecting charities, match funders and the public in a flourishing cycle of giving. As a donor, you share your personalised Givematch invitation link with your friends; when two friends make a donation (of any amount they like), you earn a match from one of our partners and get your donation doubled. This means your charity receives double the amount they would have received if you donated to them on a different platform.</p>

        <h3 className="mt-8 text-lg font-bold">Where does Givematch get its match funding from</h3>
        <p className="mt-4">Givematch has several avenues through which it seeks out match funding for charity partners. These include high net worth donors, businesses, corporations, foundations, celebrities, brands and governmental institutions. Once match funding is secured, Givematch requests that it is sent to an escrow, held under our Donor Advised Fund (DAF) partner, i.e. a charitable account. Once members of the public qualify for a match fund (i.e. successfully refer two friends to donate), Givematch instructs the DAF partner to release match funds to the charity of choice.  A list of our active match funders and DAF partners can be found on the footer of our homepage, and we are constantly working to add more.</p>

        <h3 className="mt-8 text-lg font-bold">How do I get my donation matched?</h3>
        <p className="mt-4">When you have made your own donation, you are given a personalised invite link. Share this link with your friends, family and social network. When 2 people use your invite link to make their own donation (of any amount they like), your original donation will be matched. This means your charity receives double the amount they would have received if you donated to them on a different platform.</p>

        <h3 className="mt-8 text-lg font-bold">Is Givematch a registered charity?</h3>
        <p className="mt-4">No. We are a limited company that builds culture changing software for charities. Givematch is registered under Givetree Ltd, company number:13405448.</p>

        <h3 className="mt-8 text-lg font-bold">Can I use a VPN connection to access Givematch for donation?</h3>
        <p className="mt-4">No, You can't use a VPN connection to access Givematch for donation.</p>

        <h3 className="mt-8 text-lg font-bold">Givematch’s 200% donation policy</h3>
        <p className="mt-4">Givematch is a match funding platform. We connect match funders with charities to double your donation. By a ‘200% donation policy’, we mean that your donation can be doubled by generous philanthropists who match donations made on Givematch to make your impact go twice as far. We are not a charity, and therefore do not carry admin or donation policies like your usual charities. When citing a ‘donation policy’, charities often refer to the percentage they take from your donation. Givematch takes nothing from your donation, and passes on 100% of your donation (minus any third party fees) to your charity. We do have a business model to sustain ourselves independent of your donation; please see more on our fees and business model further below.</p>

        <h3 className="mt-8 text-lg font-bold">Is the match funding already donated to the charity, regardless of whether I donate or not?</h3>
        <p className="mt-4">No. The charity will not receive the match funding if you, the donor, do not qualify your charity for it, by referring two friends to donate. We understand that match funding has been the subject of abuse in the past, and we are actively working to better the practices within the space, making it more transparent and productive for all parties involved.</p>
        <p className="mt-4">The match funding is held in an escrow (with our Donor Advised Fund partner), and not held by us or your chosen charity. The funds are only released to your charity once you, the donor, successfully refer two friends to make a donation. If you fail to refer two friends, your chosen charity will not receive the match funding and the match funding may be returned to the match funder or allocated to a different charity. </p>
        <p className="mt-4">Givematch is concerned about the misleading practices of other platforms and organisations where donors are led to believe they have a greater impact than they actually have. We are working hard to address this practice in the sector. By fundraising on Givematch, charities transparently and verifiably raise more for their causes. We make it transparent by displaying the incremental value added by Givematch to each fundraiser on their campaign page. We make it verifiable by disclosing the (contact) details of our DAF partners, who act as the third party independent escrow for our match funding. For more information on this and some of the other challenges we are working to overcome in the match funding space, please see <a className="underline text-gm-green" href="https://medium.com/@Givematch/when-match-funding-goes-bad-63daa624ff8c" target="_blank" rel="noreferrer">here</a></p>

        <h3 className="mt-8 text-lg font-bold">If I share my link with 2 people do they have to donate the same amount as me?</h3>
        <p className="mt-4">Those who donate through your link can donate any amount to their chosen charity to be eligible. It does not need to be the same amount as your donation.</p>

        <h3 className="mt-8 text-lg font-bold">Is there a limit to how much will be matched?</h3>
        <p className="mt-4">Yes. It varies depending on your chosen charity’s location. For instance, the UK, USA and Canada have different limits on how much we are able to match, and it may change from time to time based on the preference of the match funder for your chosen charity. On average we will match up to £200 GBP or the equivalent in your donation currency. The best way to see the match limit for your donation is to check our homepage at the time you wish to donate, since that will present the most up to date limit on how much we are able to match for your charity and location. To see these limits at play, please see the below examples:</p>
        <p className="mt-4">If the limit is £200 and you donate £60, we will match with another £60, bringing your total impact to £120. If you donate £200, we will match with another £200, bringing your total impact to £400. If you donate £600, we will match with another £200, bringing your total impact to £800.</p>

        <h3 className="mt-8 text-lg font-bold">What Charities can I donate to through Givematch?</h3>
        <p className="mt-4">We are currently partnering with a number of charities; however, this list is continuously growing. Please use our search functionality to check if your charity is featured. If not, please contact us directly to request we invite your charity or contact your charity to reach out to us.</p>

        <h3 className="mt-8 text-lg font-bold">How does my donation reach my chosen charity?</h3>
        <p className="mt-4">A Donor Advised Fund (DAF) is an intermediary that sits between ourselves and the charities. The purpose of this DAF is to enhance the due diligence on where funds go and how they are spent.</p>
        <p className="mt-4">Givematch has a fund, called The Givematch Fund, which is a restricted fund, operating under the auspices of our DAF partner. For UK donors, all donations will be received by The Givematch Fund held at Mercy Mission UK, a registered charity (No. 1122922). For USA donors, all donations will be received by The Givematch Fund held at Myriad USA (formerly KBF USA), a registered charity (No. 58-2277856) For CANADIAN donors, all donations will be received by The Givematch Fund held at KBF Foundation CANADA, a registered charity (No. 76978 4893 RR0001). They will receive the donations and grant the funds to your chosen charity via bank transfer or cheque. This varies in other locations, and our chosen DAF partner is displayed at the point of donation.</p>
        <p className="mt-4">Charities will typically receive the donations from our DAF partner within 15 - 45 days of your donation if the charity has successfully enrolled with the DAF partner (or 90 days if the charity has not yet enrolled).</p>
        <p className="mt-4">The DAF will also be providing Tax Receipts for all donations made through the Givematch platform as they are in effect receiving the donation from you and onward gifting it to your chosen charity. When you receive your tax receipt, this will be from the DAF and not the named charity you donated to.</p>

        <h3 className="mt-8 text-lg font-bold">How do I know that my money has reached the charity?</h3>
        <p className="mt-4">We are wholly transparent and welcome donors to query with charities if their donation was ultimately received in full. If you would like us to connect you directly with our contact at your chosen charity, we are happy to do so. Please contact our Donor Support Team on <a className="underline text-gm-green" href="mailto:contact@givematch.com">contact@givematch.com</a> to arrange this.</p>
      </div>

      <div className="flex-none bg-gradient-to-r from-white via-gm-gray-50 to-white my-8 h-0.5"></div>

      <div id="donation">
        <h2 className="my-4 text-2xl font-bold text-gm-green">The donation process</h2>

        <h3 className="mt-8 text-lg font-bold">Do we deduct anything from your donation?</h3>
        <p className="mt-4">No. We take nothing from your donation. 100% of your donation goes to the charity. If you donate £100 on Givematch, £100 will be received by the charity, minus any 3rd party fees that are outside of our control, like card processing fees.</p>
        <p className="mt-4">On your checkout, you can choose to cover these 3rd party fees on behalf of your chosen charity. If you choose to not cover those fees, they will be deducted from your donation.</p>

        <h3 className="mt-8 text-lg font-bold">What are the card processing fees?</h3>
        <p className="mt-4">Stripe charges an average of 2.9% + £0.20 per transaction, and this may vary depending on location and card type. We ask you - the donor - to bear the transaction processing fee (charged by the payment provider, Stripe) so that the charity doesn't have to. If you don’t bear the processing fee, then the charity will have to deduct it from your donation. At times, the exact Stripe fees can be difficult to determine, and there may be a surplus amount left over. This surplus amount will be given to Givematch and used purely to improve your product experience to help you stretch your impact further in the future.</p>

        <h3 className="mt-8 text-lg font-bold">What is the platform tip?</h3>
        <p className="mt-4">The tip is an optional payment which goes directly to the ongoing running and development of the Givematch platform. This can be decreased, increased or removed completely at your discretion. </p>

        <h3 className="mt-8 text-lg font-bold">Can I donate if I live outside of the countries listed?</h3>
        <p className="mt-4">Yes you can donate from anywhere in the world, however please note that if you do not live in the UK, USA, Canada or Australia, the transaction will be calculated in USD.  This will be based on your browser location settings. </p>

        <h3 className="mt-8 text-lg font-bold">I am in the process of donating, but my donation is not showing in the currency I require. How can this be changed?</h3>
        <p className="mt-4">If your donation is showing in the wrong currency, please check your browser location settings are correctly configured before contacting our Donor Support Team on <a className="underline text-gm-green" href="mailto:contact@givematch.com">contact@givematch.com</a> who will be able to assist with this.  </p>

      </div>
      <div className="flex-none bg-gradient-to-r from-white via-gm-gray-50 to-white my-8 h-0.5"></div>

      <div id="fundraiser">
        <h2 className="my-4 text-2xl font-bold text-gm-green">Fundraiser</h2>
        <h3 className="mt-8 text-lg font-bold">What is a Fundraiser?</h3>
        <p className="mt-4">Members of the public can choose to run a crowdfunding campaign on Givematch, and benefit from the match funding that used to be restricted to charities only. Previously, fundraisers have used this crowdfunding feature to fundraise whilst running a marathon, winter campaigns, and emergency response campaigns. </p>

        <h3 className="mt-8 text-lg font-bold">Can I choose any charity to Fundraise for?</h3>
        <p className="mt-4">We are working to add more and more charities on Givematch to fundraise for. Currently, you can use the search bar to see if your chosen charity is featured.</p>

        <h3 className="mt-8 text-lg font-bold">How do I get donations to my fundraiser matched?</h3>
        <p className="mt-4">Once you have set up your fundraiser, share your link with your friends, family and social network. When someone donates to your fundraiser, they will be given an invite link. They should share this invite link with their own friends, family and social network. When 2 people use their invite link to make their own donation to your fundraiser, their original donation will be doubled.</p>

        <h3 className="mt-8 text-lg font-bold">If 2 people use the invite link, do they need to donate to my fundraiser, for the original donation to be matched?</h3>
        <p className="mt-4">Yes, those who donate through the invite link must donate to your fundraiser.</p>
      </div>

      <div className="flex-none bg-gradient-to-r from-white via-gm-gray-50 to-white my-8 h-0.5"></div>

      <div id="postDonation">
        <h2 className="my-4 text-2xl font-bold text-gm-green">Post donation</h2>

        <h3 className="mt-8 text-lg font-bold">What is my password?</h3>
        <p className="mt-4">Givematch does not require you to set your own password. Instead, a one time password for your account is issued each time you want to log in. Click Login on the home page and enter the email address you used to register. A passcode will be sent to you. Please note the password can only be used once and does expire if not used within 15 minutes.</p>

        <h3 className="mt-8 text-lg font-bold">I didn’t receive a confirmation email</h3>
        <p className="mt-4">Sometimes, these emails can be found in your junk / spam mail. Please check these. If there is still no confirmation email, please contact our Donor
          Support Team on <a className="underline text-gm-green" href="mailto:contact@givematch.com">contact@givematch.com</a> who will be able to assist you with this.</p>
      </div>

      <div className="flex-none bg-gradient-to-r from-white via-gm-gray-50 to-white my-8 h-0.5"></div>

      <div id="giftAidTax">
        <h2 className="my-4 text-2xl font-bold text-gm-green">GiftAid (UK) and tax-deductibility (USA)</h2>

        <h3 className="mt-8 text-lg font-bold">Do donations to my charity of choice qualify for GiftAid in the UK?</h3>
        <p className="mt-4">Yes. Donations to Givematch qualify for GiftAid in the UK. Voluntary contributions to Givematch (clearly marked as separate from your charity donation) do not qualify for GiftAid as we are a limited company.</p>

        <h3 className="mt-8 text-lg font-bold">Are donations to my charity of choice tax-deductible in the USA or Canada?</h3>
        <p className="mt-4">Yes. Donations to our charity partners are tax-deductible in the USA and Canada.
          Voluntary tips to Givematch (clearly marked as separate from your charity donation) do not qualify for tax-deductions as we are a limited company.
        </p>

        <h3 className="mt-8 text-lg font-bold">Will I receive a Tax Receipt?</h3>
        <p className="mt-4">Yes. You will receive a tax receipt before the end of the tax year and you will not need to take any further action. However, if you need one sooner, please contact our Donor Care Team on <a className="underline text-gm-green" href="mailto:contact@givematch.com">contact@givematch.com</a> who will issue this for you.  Please note that the receipt is issued by the DAF partner relevant to your country as your donation was legally received by them and onward gifted to your chosen charity.</p>
      </div>

      <div className="flex-none bg-gradient-to-r from-white via-gm-gray-50 to-white my-8 h-0.5"></div>

      <div id="security">
        <h2 className="my-4 text-2xl font-bold text-gm-green">Security</h2>

        <h3 className="mt-8 text-lg font-bold">Is my payment secure?</h3>
        <p className="mt-4">Yes. We take security seriously and meet the security standards of the EU PSD2 directives. All our payments are processed through our payment provider, Stripe. We do not store any card details on our database.</p>

        <h3 className="mt-8 text-lg font-bold">Is my data safe?</h3>
        <p className="mt-4">Yes. We take your data seriously. Please see our privacy policy. We do not share your data with any third party without your permission unless it is required to access the service. We will only contact you or pass on your contact details to your charity of choice if you explicitly ask us to.</p>

        <h3 className="mt-8 text-lg font-bold">Can I remain anonymous?</h3>
        <p className="mt-4">All donations are anonymous to the public. You need to provide your name to validate your payment card, opt-in to marketing, and to request GiftAid or a tax receipt. Charities will only receive your full name if you opt-in to share your details. Your friend who invited you to join Givematch may see your first name only, if you chose to donate by clicking on his/her invite link.</p>

        <h3 className="mt-8 text-lg font-bold">Will you pass on my data to charities?</h3>
        <p className="mt-4">No, unless you tell us to (via the opt-in box). We’ll never pass on your data without your consent. There is an exception for a handful of charities that pass our enhanced vetting. These are charities who, for legitimate reasons, need a record of all individuals who have donated to their charity. These charities must pass our due diligence and sign our data sharing agreement, which means they can never contact you, or do anything with this data, other than the admin activities stipulated in our sharing agreement.</p>

        <h3 className="mt-8 text-lg font-bold">Will you sell or trade my data?</h3>
        <p className="mt-4">Never. We are fully GDPR compliant. We’ll never do anything to annoy you. Please see our Privacy Policy <Link className="underline text-gm-green"
          to="/privacy-policy">here</Link>.</p>

        <h3 className="mt-8 text-lg font-bold">Will you ever mishandle my data?</h3>
        <p className="mt-4">Never. We are fully GDPR compliant. We’ll never do anything to annoy you. Please see our Privacy Policy <Link className="underline text-gm-green"
          to="/privacy-policy">here</Link>.</p>

        <h3 className="mt-8 text-lg font-bold">Transparency and Accountability</h3>
        <p className="mt-4">Where can I read your terms and conditions?<br />Our Terms and Conditions are available to read <Link className="underline text-gm-green"
          to="/terms-conditions">here</Link></p>
        <p className="mt-4">Where can I read your privacy policy?<br />Our Privacy Policy is available to read <Link className="underline text-gm-green"
          to="/privacy-policy">here</Link></p>
      </div>

      <div className="flex-none bg-gradient-to-r from-white via-gm-gray-50 to-white my-8 h-0.5"></div>

      <div id="moreInfo">
        <h2 className="my-4 text-2xl font-bold text-gm-green">More Information</h2>

        <h3 className="mt-8 text-lg font-bold">Are you independent?</h3>
        <p className="mt-4">Yes</p>

        <h3 className="mt-8 text-lg font-bold">Who runs Givematch?</h3>
        <p className="mt-4">Givematch is run by Givetree Ltd. We are a group of tech entrepreneurs from the UK that have decided to apply the power of technology to giving. The result is Givematch. We believe we can transform the world into a better place much faster by applying technology to the charitable sector.</p>

        <h3 className="mt-8 text-lg font-bold">How long has Givematch been around?</h3>
        <p className="mt-4">Since 2020! In total, we have raised tens of millions of pounds for charities all over the world, from over 200,000 donors.</p>

        <h3 className="mt-8 text-lg font-bold">How does Givematch sustain itself?</h3>
        <p className="mt-4">Givematch is a Saas platform. Our top priority is delivering high returns for our customers through our complete solution pricing model, tailored to our customer needs. Our pricing is a combination of a monthly or annual subscription with a service fee associated with the use of our software. We never take any cut of public donations, and nor do we ever price our services as a percentage of donations raised. You can be reassured that 100% of your donation reaches the charity, minus any fees deducted by third parties outside of our control, such as card processing fees.</p>
        <p className="mt-4">Holding ourselves to a higher standard of transparency, your donation is never handled by us, and nor do we ever have any control over it. Your donation moves directly from your bank account into the trusted hands of an independent 3rd party charity account, called a Donor Advised Fund (DAF). DAFs work as a regulator for us, handling all of your money and ensuring it is handled responsibly by both ourselves and your chosen end charity. In the vein of transparency, we welcome all donors, charities and corporate partners to liaise directly with our chosen DAF partners for any further queries.</p>
        <p className="mt-4">The details for our DAF partners are:</p>
        <ul>
          <li className="flex flex-col items-start mt-4">
            <p>United Kingdom:</p>
            <p>Mercy Mission UK, registered charitable organization number 1122922</p>
          </li>
          <li className="flex flex-col items-start mt-4">
            <p>Canada:</p>
            <p>KBF CANADA, registered charitable organization number 76978 4893 RR0001</p>
          </li>
          <li className="flex flex-col items-start mt-4">
            <p>United States of America:</p>
            <p>Myriad USA (formerly KBF USA), registered charitable organization number 58-2277856</p>
          </li>
          <li className="flex flex-col items-start mt-4">
            <p>Australia:</p>
            <p>Myriad Australia Limited, registered charitable organization number ABN 20 640 318 636</p>
          </li>
        </ul>

        <h3 className="mt-8 text-lg font-bold">How can I support Givematch?</h3>
        <p className="mt-4">You can add a voluntary donation at the end of the donation process.</p>

        <h3 className="mt-8 text-lg font-bold">How can I become a charity partner?</h3>
        <p className="mt-4">Please contact us on <a className="underline text-gm-green" href="mailto:contact@givematch.com">contact@givematch.com</a> and we will direct you to the
          appropriate team.</p>

        <h3 className="mt-8 text-lg font-bold">How do I access donor support?</h3>
        <p className="mt-4">You can contact our Donor Support Team by emailing <a className="underline text-gm-green" href="mailto:contact@givematch.com">contact@givematch.com</a></p>
      </div>
    </Page>
    <Footer />
  </>
}

export default FAQs
