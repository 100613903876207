import { useMemo, type FC } from 'react'
import { type EventDonation } from '~/state/charity'
import { DonorCard } from './FundraiserDonation'
import { type Currency, displayTidyCurrency, GetCurrencyByString } from '@lib/services'

interface props {
  donations: EventDonation[]
}
export const DonationList: FC<props> = ({ donations }) => {
  const totalAmountDonated = useMemo(() => {
    let currency: Currency | undefined
    let donationsTotal = 0
    donations.forEach((donation) => {
      if (!currency) currency = GetCurrencyByString(donation.currency)
      donationsTotal += Object.values(donation.cause_donations).reduce((acc, value) => acc + value, 0)
    })
    return currency ? displayTidyCurrency(donationsTotal, currency, true) : '0'
  }, [donations])
  return <section className="flex flex-col pt-6">
      <span className='font-semibold mb-2'>
        A total of {totalAmountDonated} donated from {donations.length} {donations.length === 1 ? 'donor' : 'donors'}
      </span>

      <ul className='flex flex-col gap-y-4'>
        {/* Donation Item component  */}
        {donations.map((donation, i) => (
          <li key={i}>
            <DonorCard donation={donation} />
          </li>
        ))}
      </ul>

      {/* More component  */}
      {/* <div className="text-sm underline text-gm-green-500 cursor-pointer">
        <span> See all donations</span>
      </div> */}
    </section>
}
