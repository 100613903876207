import { isFundraiserCharity, CauseType, isCause } from '@givematch/common'
import { callService, isIframe } from '@lib/services'
import { HttpRequestMethod } from 'http-core-constants'
import { Tracking } from '~/service/tracking'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getStripeToken = async () => {
  const res = await fetch('/api/payment/token')

  if (res.status !== 200) {
    throw new Error('Error getting token')
  }

  const response = await res.json()
  return response.stripe_token
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createPaymentIntent = async (donation, formData) => {
  const body = {
    charity: donation.charity.charity,
    cause_type: donation.causeType ?? CauseType.CHARITY,
    amount: isCause(donation.causeType) ? 0 : donation.amount,
    donation_currency: donation.donationCurrency,
    fundraiser_id: isFundraiserCharity(donation.causeType) ? donation.fundraiserID : '',
    cause_amounts: isCause(donation.causeType) ? donation.causeAmounts : {},
    pay_fees: formData.payFees,
    gift_aid: formData.giftAid,
    tax_receipt: formData.taxReceipt,
    platform_tip: formData.platformTip,
    charity_marketing_email: formData.charityMarketingEmail,
    givematch_marketing_email: formData.givematchMarketingEmail,
    remain_anonymous: formData.remainAnonymous,
    chain_code: donation.chainCode,
    billing_details: createBillingDetailsBody({ ...formData }),
    payment_type: formData.paymentType,
    fundraiser_notification: formData.fundraiserNotification,
    ...Tracking.getCharityExtraData(),
    native: !isIframe(),
    landing_page: donation.landingPage,
    ramadan_marketing_email: formData.ramadanEmail,
    token: formData.token
  }

  return callService('/api/payment/create', HttpRequestMethod.POST, body)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const updatePaymentIntent = async (shareCode, paymentIntentID, formData, donation) => {
  const body = {
    share_code: shareCode,
    payment_intent_id: paymentIntentID,
    charity: donation.charity.charity,
    cause_type: donation.causeType ?? CauseType.CHARITY,
    amount: isCause(donation.causeType) ? 0 : donation.amount,
    donation_currency: donation.donationCurrency,
    fundraiser_id: isFundraiserCharity(donation.causeType) ? donation.fundraiserID : '',
    cause_amounts: isCause(donation.causeType) ? donation.causeAmounts : {},
    pay_fees: formData.payFees,
    gift_aid: formData.giftAid,
    tax_receipt: formData.taxReceipt,
    platform_tip: formData.platformTip,
    charity_marketing_email: formData.charityMarketingEmail,
    givematch_marketing_email: formData.givematchMarketingEmail,
    remain_anonymous: formData.remainAnonymous,
    chain_code: donation.chainCode,
    billing_details: createBillingDetailsBody({ ...formData }),
    payment_type: formData.paymentType,
    fundraiser_notification: formData.fundraiserNotification,
    ...Tracking.getCharityExtraData(),
    native: !isIframe(),
    landing_page: donation.landingPage,
    ramadan_marketing_email: formData.ramadanEmail
  }
  return callService('/api/payment/update', HttpRequestMethod.POST, body)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createBillingDetailsBody = ({ firstName, lastName, email, postcode, country, firstAddress, addressLine1, addressLine2, city, state }) => {
  return {
    first_name: firstName,
    last_name: lastName,
    email,
    first_address: firstAddress,
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    city,
    state,
    postcode,
    country
  }
}

export {
  getStripeToken,
  createPaymentIntent,
  updatePaymentIntent
}
