import { type FC } from 'react'
import number1Icon from '~/assets/icons/no1.svg'
import shieldIcon from '~/assets/icons/shield.svg'
import clockIcon from '~/assets/icons/clock.svg'

const AboutGivematch: FC = () => {
  return <section className="my-10 bg-white text-left">
    <h1 className="text-2xl tracking-tighter text-gm-green font-bold">
      About Givematch
    </h1>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-10 my-5">
      <div className='flex items-start'>
        <img src={number1Icon} className="mr-3" alt='profile'/>
        <div className="space-y-5">
          <div className='text-lg font-semibold'>Innovative platform Doubling</div>
          <div>Whenever you give to a charity through Givematch, we can double your donation.</div>
          <div className="underline text-gm-green"><a href="/about-us">How does Givematch work?</a></div>
        </div>
      </div>
      <div className='flex items-start'>
        <img src={shieldIcon} className="mr-3" alt='profile'/>
        <div className="space-y-5">
          <div className='text-lg font-semibold'>Givematch protects your donation</div>
          <div>We take your data seriously. We do not share your data with any third party without your permission unless it is required to access the service.</div>
          <div className="underline text-gm-green"><a href="/privacy-policy">Learn how we protect your donations.</a></div>
        </div>
      </div>
      <div className='flex items-start'>
        <img src={clockIcon} className="mr-3" alt='profile'/>
        <div className="space-y-5">
          <div className='text-lg font-semibold'>Experts in tech and charity</div>
          <div>We are a group of tech entrepreneurs from the UK that have decided to apply the power of technology to giving.</div>
          <div className="underline text-gm-green"><a className="px-4" href="/contact">Visit our Help Center.</a></div>
        </div>
      </div>
    </div>
  </section>
}

export default AboutGivematch
