export interface FundraiserProgressValues {
  nonReferredDonations: number
  referredDonations: number
  matchedAmount: number
  donationsTotal: number
  fullTotalAmount: number

  fundraiserTarget: number

  nonReferredPercentage: number
  referredPercentage: number
  donationsPercentage: number
  matchedPercentage: number
  fullTotalPercentage: number
}

export const fundraiserProgressValues = (
  nonReferredDonations: number,
  referredDonations: number,
  matchedAmount: number,
  target: number
): FundraiserProgressValues => {
  const donationsTotal = nonReferredDonations + referredDonations
  const fullTotalAmount = donationsTotal + matchedAmount

  const nonReferredPercentage = (nonReferredDonations / target) * 100
  const referredPercentage = (referredDonations / target) * 100
  const donationsPercentage = (donationsTotal / target) * 100
  const matchedPercentage = (matchedAmount / target) * 100
  const fullTotalPercentage = (fullTotalAmount / target) * 100
  return {
    nonReferredDonations,
    referredDonations,
    matchedAmount,
    donationsTotal,
    fullTotalAmount,

    fundraiserTarget: target,

    nonReferredPercentage,
    referredPercentage,
    donationsPercentage,
    matchedPercentage,
    fullTotalPercentage
  }
}
