import { Button } from '@lib/components'
import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import type Fundraiser from '~/state/fundraiser'
import moment from 'moment'
import { type Charity } from '~/state/charity'
import { compactNumber, displayTidyCurrency, isProd } from '@lib/services'
import ProgressBar from '@ramonak/react-progress-bar'
import defaultFundraiserImage from '~/assets/fundraiser/fundraiser-default.jpeg'
import { fundraiserProgressValues } from '@givematch/common'

const GenerateCardContent: FC<props> = ({ fundraiser }) => {
  const navigate = useNavigate()
  return <div className="p-4">
        <p className="py-2">{fundraiser.title}</p>
        <ul className="px-2 text-xs flex flex-col gap-1 list-disc">
            <li>{fundraiser.charity_name}</li>
            {!!fundraiser.published_time && <li>Published {moment(fundraiser.published_time).fromNow()} </li>}
        </ul>
        <div className='py-2'>
            <Button
                className="w-full mt-5 rounded-lg shadow-md"
                variant='gm-share'
                onClick={() => {
                  navigate(Navigation.fundraiserDetails(fundraiser.fundraiser_id))
                }}
            >
                <div className='flex items-center justify-center'>
                    <span className='text-lg'>
                        Manage
                    </span>
                </div>

            </Button>
        </div>
    </div>
}

const GenerateDetailsCardContent: FC<props> = ({ fundraiser, charity }) => {
  const navigate = useNavigate()

  const { fullTotalAmount, fundraiserTarget, fullTotalPercentage } = fundraiserProgressValues(
    fundraiser.non_referral_donation_amount,
    fundraiser.referral_donation_amount,
    fundraiser.matched_amount,
    fundraiser.target_amount
  )
  return <div className="p-4">
        <p className="py-2">{fundraiser.title}</p>
        <ProgressBar className='w-full' completed={fullTotalPercentage} bgColor='#02A47B' height='3px' isLabelVisible={false} />
        {charity && <div className="flex flex-row py-2">
            <div className="w-1/3 text-lg font-bold">
                <span> {displayTidyCurrency(fullTotalAmount, charity.currency, true)} </span>
            </div>
            <div className="w-2/3 text-xs">
                <span>
                    raised of {displayTidyCurrency(fundraiserTarget, charity.currency, true)}{' '}
                    - {compactNumber(fundraiser.donation_count)} donors plus matches on behalf of {fundraiser.charity_name}
                </span>
            </div>
        </div>}
        <div className='py-2 flex flex-row-reverse gap-2'>
            <div className="w-1/2">
                <Button
                    className="w-full mt-5 rounded-lg shadow-md"
                    variant='gm-share'
                    onClick={() => {
                      navigate(Navigation.shareFundraiser(fundraiser.fundraiser_id))
                    }}
                >
                    <div className='flex items-center justify-center'>
                        <span className='text-sm'>
                            Share
                        </span>
                    </div>

                </Button>
            </div>
            {/* <div className="w-1/2">
                <Button
                    className="w-full mt-5 rounded-lg shadow-md"
                    variant='gm-primary'
                    onClick={() => {
                      alert('not implemented')
                    }}
                >
                    <div className='flex items-center justify-center'>
                        <span className='text-sm'>
                            Post Update
                        </span>
                    </div>

                </Button>
            </div> */}
        </div>
    </div>
}
interface props {
  isDetails: boolean
  fundraiser: Fundraiser
  charity?: Charity
  className?: string
}
export const FundRaiserImage: FC<{ classname: string, image: string, fundraiserId: string }> = ({ classname, fundraiserId, image }) => {
  let imgSrc
  if (image.startsWith('data:image')) { // we need a way to render base64 directly, this is useful for edit section
    imgSrc = image
  } else {
    const imagePrefix: string = isProd() ? '' : 'https://givematch.link'
    imgSrc = imagePrefix + image
  }
  return <img className={classname} src={imgSrc} alt={fundraiserId} onError={(e) => (e.currentTarget.srcset = defaultFundraiserImage)} />
}
const FundRaiserCard: FC<props> = ({ isDetails, fundraiser, charity, className }) => {
  return <section className={`border rounded border-gm-green-300 shadow ${className}`}>
        <div className="relative">
            <FundRaiserImage classname='rounded-xl w-full' image={fundraiser.newCoverPhoto ?? fundraiser.image_path} fundraiserId={fundraiser.fundraiser_id} />
            {/* <div className={`absolute border border-transparent rounded-2xl px-3 py-1 bg-white text-gm-green-500 bottom-4 right-4 text-sm md:bottom-2 md:right-2 md:text-xs`}>{fundraiser.active ? 'Published' : 'Unpublished'}</div> */}
        </div>
        {
            isDetails
              ? <GenerateDetailsCardContent isDetails={true} fundraiser={fundraiser} charity={charity} />
              : <GenerateCardContent isDetails={false} fundraiser={fundraiser} />
        }
    </section>
}
export default FundRaiserCard
