import { type FC } from 'react'
import { type PaymentFormData } from '../data/paymentFormData'
import { type UseFormReturn } from 'react-hook-form'
import { useAuth } from '@lib/hooks'
import { type StripeLinkAuthenticationElementChangeEvent, type StripePaymentElementChangeEvent, type StripeAddressElementChangeEvent } from '@stripe/stripe-js'
import { AddressElement, LinkAuthenticationElement, PaymentElement } from '@stripe/react-stripe-js'
import { Spinner } from '@lib/components'

interface Props {
  form: UseFormReturn<PaymentFormData>
  formLoading: boolean
  setFormLoading: (loading: boolean) => void
}

const PaymentOptions: FC<Props> = ({ form, formLoading, setFormLoading }) => {
  const { user } = useAuth()

  if (user) {
    form.setValue('email', user.email)
    form.setValue('firstName', user.firstName)
    form.setValue('lastName', user.lastName)
  }

  const setEmail = (email: string): void => {
    form.setValue('email', email)
  }

  const onChangePaymentMethod = (event: StripePaymentElementChangeEvent): void => {
    console.log('onChangePaymentMethod', event)
  }

  const onChangeLinkAuthElement = (event: StripeLinkAuthenticationElementChangeEvent): void => {
    setEmail(event.value.email)
    setFormLoading(false)
  }

  const onChangeAddressElement = (event: StripeAddressElementChangeEvent): void => {
    form.setValue('country', event.value.address.country)
    let firstName: string = ''
    let lastname: string = ''
    if (event.value.name !== '') {
      const nameSplit = event.value.name.split(' ')
      firstName = nameSplit ? nameSplit[0] : ''
      lastname = nameSplit ? nameSplit.slice(1).join(' ') : ''
    } else if (event.value.firstName !== '' && event.value.lastName !== '') {
      firstName = event.value.firstName!
      lastname = event.value.lastName!
    }
    form.setValue('firstName', firstName)
    form.setValue('lastName', lastname)
    const addressElementArray = [
      event.value.address.line1,
      event.value.address.line2,
      event.value.address.city,
      event.value.address.state
    ]
    const completeAddress = addressElementArray.filter(item => item).join(', ')
    form.setValue('firstAddress', completeAddress)
    form.setValue('addressLine1', event.value.address.line1)
    form.setValue('addressLine2', event.value.address.line2 ?? '')
    form.setValue('city', event.value.address.city)
    form.setValue('state', event.value.address.state)
    form.setValue('postcode', event.value.address.postal_code)
  }

  return <div className='flex flex-col gap-y-4'>
    <div className='flex flex-col gap-y-4'>
      <LinkAuthenticationElement onChange={onChangeLinkAuthElement} />
      <AddressElement options={{ mode: 'shipping', display: { name: 'split' } }} onChange={onChangeAddressElement} />
      {!formLoading ? <PaymentElement options={{ fields: { billingDetails: { address: { country: 'never', postalCode: 'never' } } } }} onChange={onChangePaymentMethod} /> : <Spinner className="m-auto" />}
    </div>
  </div>
}

export default PaymentOptions
