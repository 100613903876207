import { type FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Spinner } from '@lib/components'

import { ShareCodeError, shareDetails } from '~/state/shareDetails'

import Footer from '~/components/Footer'
import Header from '~/components/Header'

import '~/index'

import Error from './Error'
import StepsExplainer from '~/components/StepsExplainer'
import { CauseType, isCause, isFundraiserCharity } from '@givematch/common'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import Fundraiser from '~/components/FundRaiser/Fundraiser'
import { SessionStorage } from '~/service/sessionStorage'
import { LandingPageWithCharity } from '~/components/LandingPageWithCharity'
import { type Currency } from '@lib/services'

const ShareLanding: FC = () => {
  const { chainCode } = useParams() as { chainCode: string }
  const [error, setError] = useState('')
  const [fundraiserID, setFundraiserID] = useState('')
  const [charityId, setCharityId] = useState('')
  const [causeType, setCauseType] = useState(CauseType.CAUSE)
  const [landingPage, setLandingPage] = useState('')
  const { donation, setDonation } = useDonationPlan()
  const [donorFirstName, setDonorFirstName] = useState('')
  const [donorLastName, setDonorLastName] = useState('')
  const [causeDonation, setCauseDonation] = useState<Record<string, number>>({})
  const [currency, setCurrency] = useState<Currency | string>('')
  const { isLoading: shareLoading, isError } = useQuery(['shareDetails', chainCode], async () => shareDetails(chainCode), {
    onSuccess: (res) => {
      setCharityId(res.charity)
      setCauseType(res.cause_type)
      setFundraiserID(res.fundraiser_id)
      setCauseDonation(res.cause_donation)
      setCurrency(res.currency)
      setDonorFirstName(res.first_name)
      setDonorLastName(res.last_name)
      setDonation({ ...donation, eventID: res.event_id, causeType: isCause(res.cause_type) ? CauseType.CAUSE : res.cause_type, chainCode })

      sessionStorage.setItem(SessionStorage.partnerCharityKey, JSON.stringify({
        name: res.partner_charity_name,
        id: res.partner_charity_id
      }))

      setLandingPage(res.landing_page)
    },
    onError: (err) => {
      if (err instanceof ShareCodeError) {
        setError('The share code appears to be invalid')
      } else {
        setError('Unable to retrieve share details.')
      }
    }
  })

  if (error !== '') {
    return <Error errorMessage={error} />
  }

  if (isFundraiserCharity(causeType)) {
    const fundraiserProps = {
      fundraiserID,
      referrerCharityId: charityId,
      donorFirstName,
      donorLastName,
      causeDonation,
      currency
    }
    return <Fundraiser {...fundraiserProps} />
  }

  return <div className="flex flex-col justify-around sm:text-center">
    <Header />
    {shareLoading && <Spinner className="m-auto" />}
    {isError && <p className="px-4 mt-4 text-gm-red"> We were unable to find your friend's link </p>}
    {!shareLoading && !isError && error === '' && charityId && <LandingPageWithCharity referrerCharityId={charityId} landingPage={landingPage} />}
    <StepsExplainer landingPage={landingPage} />
    <Footer />
  </div>
}

export default ShareLanding
