import { type FC, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { useAuth } from '@lib/hooks'
import { useExperiment } from '@lib/react-ab-test'
import { Currency } from '@lib/services'
import { CauseType, LandingPageType } from '@givematch/common'

import DonationCard from '~/components/DonationCard'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { Navigation } from '~/service/navigation'
import { Tracking } from '~/service/tracking'
import { ActiveExperiments, ExplainerPageRemovedVariants } from '~/splitTesting/splittesting'
import { type Charity } from '~/state/charity'
import { ModalBtn, ModalNonApprovedAPICharity } from '~/components/ModalNonApprovedAPICharity'
import infoIcon from '~/assets/info.svg'

interface props {
  charityId?: string
  charityName?: string
  charityCurrency?: Currency
  logoImage?: string
  landingPage: string
  isNotApprovedCharityAPI?: boolean
}
const ReferralInfo: FC = () => {
  return <div className='bg-gm-purple w-full px-4 py-2 my-4 rounded'>
    <div className='flex lg:justify-start justify-center items-center gap-x-4'>
      <img src={infoIcon} alt='info' className='w-4 h-4' /> <span>
        Donations made through this page are general donations to the charity and are not associated with any specific cause.
      </span>
    </div>
  </div>
}
const DonationHero: FC<props> = ({ charityName, charityId, charityCurrency, logoImage, landingPage, isNotApprovedCharityAPI = false }) => {
  const { donation, setDonation } = useDonationPlan()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user } = useAuth()
  const { activeVariant: explainerPageRemovedVariant } = useExperiment(ActiveExperiments.ExplainerPageRemoved)
  const [showModal, setShowModal] = useState(false)

  return <section className="px-4 py-6 sm:px-20 md:px-24 lg:px-40 lg:flex-1 w-full bg-grey-split">
    {landingPage === LandingPageType.CharityRoot && <div className='hidden sm:block'><ReferralInfo /></div>}
    <div className="my-auto flex flex-col items-center sm:flex-row sm:justify-between">
      <div className="flex flex-col justify-evenly sm:py-10 items-center sm:items-start sm:mr-4 mb-10">
        <div className="flex flex-col justify-center text-4xl font-bold tracking-tighter md:text-5xl lg:text-6xl whitespace-nowrap text-center sm:text-left mb-5 sm:mb-10">
          <h2 className="text-gm-green text-5xl">You Give</h2>
          <h2 className='text-5xl'>We Match</h2>
        </div>
      </div>
      {landingPage === LandingPageType.CharityRoot && <div className='sm:hidden'><ReferralInfo /></div>}
      <DonationCard
        onButtonClick={(amount: number) => {
          setDonation({ ...donation, amount, causeType: CauseType.CHARITY, fundraiserTitle: '', fundraiserID: '', causeAmounts: {}, landingPage })
          Tracking.proceedToChooseCharity(amount, donation.donationCurrency)
          if (charityId) {
            // API Charity not approved
            if (isNotApprovedCharityAPI) {
              const selectedCharity = {
                name: charityName ?? '',
                charity: charityId,
                currency: charityCurrency ?? Currency.GBP
              }
              console.log('[DonationHero][onButtonClick] non-approved charity', { selectedCharity, user })
              setShowModal(true)
              Tracking.selectAPICharityNotApproved(selectedCharity as Charity, donation.amount, pathname, user?.email)
            } else {
              // Regular flow, navigate further
              if (explainerPageRemovedVariant === ExplainerPageRemovedVariants.present) {
                navigate(Navigation.explainer())
              } else {
                navigate(Navigation.payment())
              }
            }
          } else {
            navigate(Navigation.selectCharity())
          }
        }}
        donateButton={false}
        charityName={charityName}
        charityId={charityId}
        logoImage={logoImage}
        showFundraiserButton={true} />
    </div>

    {showModal && (
      <ModalNonApprovedAPICharity
        showModal={showModal}
        setShowModal={setShowModal}
        charityName={charityName ?? ''}
        cta1={(
          <ModalBtn
            primary={true}
            onClick={() => { setShowModal(false); navigate(Navigation.home()) }}
          >
            Select another charity
          </ModalBtn>
        )}
      />
    )}
  </section>
}

export default DonationHero
