import { type FC } from 'react'
import moment from 'moment'
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Button } from '@lib/components'
import { compactNumber, displayTidyCurrency, GetCurrencyByString } from '@lib/services'
import { Experiment, Variant } from '@lib/react-ab-test'
import { fundraiserProgressValues } from '@givematch/common'
import { ActiveExperiments, DonationAmountPageVariants } from '~/splitTesting/splittesting'
import { type Charity, type EventDonation } from '~/state/charity'
import profile from '~/assets/icons/profile.svg'
import profileDark from '~/assets/icons/profile-dark-green.svg'
import warning from '~/assets/warning.svg'
import DonationCard from '~/components/DonationCard'
import AboutGivematch from '~/components/AboutGivematch'
import type Fundraiser from '~/state/fundraiser'
import { type Organiser } from '~/state/fundraiser'
import { useDonationPlan } from '~/hooks/useDonationPlan'
import { YoutubeEmbed } from '~/components/YoutubeEmbed'
import { FundRaiserImage } from './FundRaiserCard'
import { useNavigate } from 'react-router-dom'
import { Navigation } from '~/service/navigation'
import { Tracking } from '~/service/tracking'
import { ToolTip } from '~/components/ToolTip'
import { Editor } from './Editor'
import '~/css/editor.css'
import {
  shouldUseTempFeature,
  TempCharityFeature
} from './temp-charity-feature/TempCharityFeature'
import { DonationButtonStandalone } from './DonationButtonStandalone'

interface Props {
  fundraiser: Fundraiser
  onDonateButtonClick: (amount: number) => void
  eventID: string
  charity: Charity
  isPreview: boolean
}

export const DonorCard: FC<{ donation: EventDonation }> = ({ donation }) => {
  const donationAmount = Object.values(donation.cause_donations).reduce((acc, value) => acc + value, 0)
  return <div key={`${donation.first_name}${donation.last_name}`} className="flex mt-5">
    <img src={profile} className="mr-3" alt='profile' />
    <div className="flex flex-col">
      <div>{donation.anonymous ? 'Anonymous' : `${donation.first_name} ${donation.last_name}`}</div>
      <div><strong>{displayTidyCurrency(donationAmount, GetCurrencyByString(donation.currency), true)}</strong> <span className="text-bluegray-300">- {moment(donation.created_time).fromNow()}</span></div>
    </div>
  </div>
}

const FundraiserProgressDetails: FC<{ fundraiser: Fundraiser }> = ({ fundraiser }) => {
  const { donation } = useDonationPlan()
  const shouldUseTemp = shouldUseTempFeature(fundraiser.charity_id)
  const {
    donationsTotal,
    matchedAmount,
    fullTotalAmount,
    fundraiserTarget,
    donationsPercentage,
    fullTotalPercentage
  } = fundraiserProgressValues(
    fundraiser.non_referral_donation_amount,
    fundraiser.referral_donation_amount,
    shouldUseTemp ? fundraiser.non_referral_donation_amount + fundraiser.referral_donation_amount : fundraiser.matched_amount,
    fundraiser.target_amount
  )
  const flooredDonationsPercent = Math.floor(donationsPercentage)
  const flooredTotalPercent = Math.floor(fullTotalPercentage)
  return <div className='flex text-gm-green-900'>
    <div className='my-auto w-24 h-24'>
      <CircularProgressbarWithChildren
        value={flooredTotalPercent}
        strokeWidth={15}
        styles={buildStyles({
          textColor: '#003232',
          pathColor: '#003232',
          trailColor: '#E6F6F2',
          strokeLinecap: 'butt'
        })}
        text={`${flooredTotalPercent}%`}
        counterClockwise
      >
        {/* Foreground path */}
        <CircularProgressbar
          value={flooredDonationsPercent}
          strokeWidth={15}
          styles={buildStyles({
            pathColor: '#02A47B',
            trailColor: 'transparent',
            strokeLinecap: 'butt'
          })}
          counterClockwise
        />
      </CircularProgressbarWithChildren>
    </div>
    <div className='flex flex-col ml-2'>
      <span className='text-xl font-bold'>{displayTidyCurrency(fullTotalAmount, donation.donationCurrency, true)}</span>
      <span className="text-sm font-light">raised of {displayTidyCurrency(fundraiserTarget, donation.donationCurrency, true)} - {compactNumber(fundraiser.donation_count ?? 0)} donors</span>
      <div className='relative mt-3'>
        <span className="text-md font-thin text-gm-green">You raised: {displayTidyCurrency(donationsTotal, donation.donationCurrency, true)}</span>
        <ToolTip>This is how much people have donated to this fundraiser via Givematch.</ToolTip>
      </div>
      <div className='relative'>
        <span className="text-md font-thin">
          <TempCharityFeature
            usual={'Givematch'}
            temp={'Vitol Foundation have'}
            charityId={fundraiser.charity_id}
          />{' '}
          added: {displayTidyCurrency(matchedAmount, donation.donationCurrency, true)}
        </span>
        <ToolTip>
          <TempCharityFeature
            usual={'The amount Givematch has added by matching donations.'}
            temp={'The amount Vitol are adding by matching donations.'}
            charityId={fundraiser.charity_id}
          />
        </ToolTip>
      </div>
    </div>
  </div>
}

const FundraiserStory: FC<Props> = ({ fundraiser, eventID, isPreview }) => {
  const { donation } = useDonationPlan()
  const navigate = useNavigate()
  const onShareButtonClick = (): void => {
    Tracking.shareFundraiserButton()
    navigate(Navigation.shareFundraiser(eventID))
  }
  const disableButton = isPreview || donation.amount < 500
  return <div className='w-full border-b py-6 gap-y-4 border-gm-green'>
    <TempCharityFeature
      usual={''}
      temp={<h2 className='text-lg font-bold'>About this cause</h2>}
      charityId={fundraiser.charity_id}
    />
    <div data-color-mode="light" className='whitespace-pre-wrap'>
      {fundraiser.story.charAt(0) === '<'
        ? <Editor story={fundraiser.story} className="editor" />
        : fundraiser.story}
    </div>
    {fundraiser.active && <div className={`flex justify-between gap-x-2 py-3 ${disableButton ? ' cursor-not-allowed' : ''}`}>
      <Button variant='gm-secondary-md'
        className={`border-gm-green rounded-full w-full sm:w-auto h-14 px-7 py-1 text-gm-green font-bold text-xl ${isPreview ? ' cursor-not-allowed' : ''}`}
        onClick={onShareButtonClick} disabled={isPreview}
      >
        Share
      </Button>
    </div>}
  </div>
}
const OrganiserLabel: FC<{ organiser: Organiser }> = ({ organiser }) => {
  return <>
    {organiser.first_name && <div className='w-full flex gap-x-2 items-center text-gm-green '>
      <img src={profileDark} alt="donor" className='h-6 w-6' />
      <span className='tracking-tight text-sm font-light'>  {organiser.first_name + ' ' + organiser.last_name} is organizing this campaign</span>
    </div>}
  </>
}

const RecentDonations: FC<{ donations: EventDonation[], totalCount: number }> = ({
  donations,
  totalCount
}) => {
  return <div className="flex flex-col gap-1 py-6 border-b border-gm-green">
    <h2 className="text-xl font-bold">Donations ({compactNumber(totalCount)})</h2>
    {donations?.map((donor, index) => <DonorCard key={index} donation={donor} />)}
  </div>
}

const OrganiserCard: FC<{ organiser: Organiser, charity: Charity }> = ({
  organiser,
  charity
}) => {
  return <div className='w-full border-b border-gm-green flex gap-2 flex-col py-6'>
    <h5 className='font-bold text-lg'> Organizer</h5>
    {organiser.first_name && <div className='flex flex-row gap-2 py-2 text-xs'>
      <img src={profile} alt="donor" className='h-6 w-6' />
      <div className=''>
        <h6 className='font-bold'> {organiser.first_name + ' ' + organiser.last_name}</h6>
        <p> Organizer</p>
      </div>

    </div>}
    <div className='flex flex-row gap-2 py-2 text-xs'>
      <img src={profile} alt="beneficiary" className='h-6 w-6' />
      <div>
        <h6 className='font-bold'> {charity.name}</h6>
        <p> Beneficiary</p>
      </div>

    </div>
  </div>
}

interface ExperimentProps {
  onDonateClick: (amount: number) => void
  noDonation: boolean
  charity: Charity
}
const DonationPageExperiment: FC<ExperimentProps> = ({
  onDonateClick,
  noDonation,
  charity
}) => {
  return (
    <Experiment name={ActiveExperiments.DonationAmountPage}>
      <Variant name={DonationAmountPageVariants.donationPage}>
        <DonationButtonStandalone
          onClick={() => { onDonateClick(0) }}
          noDonation={noDonation}
        />
      </Variant>
      <Variant name={DonationAmountPageVariants.donationBox}>
        <DonationCard
          onButtonClick={onDonateClick}
          donateButton={true}
          charityId={charity.charity}
          charityName={charity.name}
          notAcceptingDonation={noDonation}
          showFundraiserButton={false}
        />
      </Variant>
    </Experiment>
  )
}

export const FundraiserDonation: FC<Props> = (props) => {
  const { fundraiser, onDonateButtonClick, eventID, charity, isPreview } = props
  // const CommentCard = ():JSX.Element => {
  //   return <div className='flex flex-row gap-x-2 py-2 text-xs'>
  //     <img src={profile} alt="commenter" className='h-6 w-6' />
  //     <div className=''>
  //       <h6 className='font-bold'> Ismael Dainehine </h6>
  //       <p> <span className='font-bold'> $10,000</span> <span className='text-sz-gray-300'> - 4 days ago</span></p>
  //       <p className=' text-sz-gray-400 py-2'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
  //     </div>

  //   </div>
  // }
  // const generateCommentCard = (): React.ReactNode => {
  //   return <>
  //     <div className='w-full border-b py-6 border-gm-green flex gap-x-2 flex-col'>
  //       <h1 className='font-bold text-lg'> Comments (24)</h1>
  //       <span className=' text-sz-gray-300 text-xs py-2'> Please donate to share words of support </span>
  //       <CommentCard />
  //       <CommentCard />

  //     </div>
  //   </>
  // }

  return <section className={`${isPreview ? '' : 'px-4'} py-2 md:py-5 md:px-12 lg:px-40 lg:flex-1 w-full text-gm-green-900`}>
    <div className={`grid grid-cols-1 xl:grid-cols-3 gap-x-10 gap-y-5`}>
      <div className="col-span-1 xl:col-span-2">
        {!fundraiser.active && <div className='flex items-center border border-gm-yellow p-3 my-3 rounded-lg'>
          <img src={warning} className="mr-3 w-6 h-6" alt='warning' />
          This fundraiser is not receiving donations at the moment
        </div>}
        <div className="flex flex-col  gap-y-2">
          {fundraiser.youtube_url && <YoutubeEmbed classname="rounded-xl w-full md:h-96" embedUrl={fundraiser.youtube_url} />}
          {!fundraiser.youtube_url && <FundRaiserImage classname='rounded-xl w-full' image={fundraiser.newCoverPhoto ?? fundraiser.image_path} fundraiserId={eventID} />}
          <h1 className="font-bold my-1 text-2xl md:text-2xl">
            {fundraiser.title}
          </h1>
          <TempCharityFeature
            usual={
              <div className="flex flex-col gap-y-2">
                <OrganiserLabel organiser={fundraiser.organiser} />
              </div>
            }
            charityId={fundraiser.charity_id}
          />
        </div>
        <div>
          <div className="flex-col gap-y-10 hidden md:inline">
            <FundraiserStory {...props} />
          </div>
          {/* <div className="flex-col gap-y-2 hidden md:inline">
            {generateCommentCard()}
          </div> */}
        </div>

      </div>

      <div className="w-full sm:max-w-sm flex flex-col gap-5 col-span-1 xl:col-start-3">
        <FundraiserProgressDetails fundraiser={fundraiser} />
        <TempCharityFeature
          usual={
            <DonationPageExperiment
              onDonateClick={onDonateButtonClick}
              noDonation={isPreview || !fundraiser.active}
              charity={charity}
            />
          }
          temp={
            <DonationCard
              onButtonClick={onDonateButtonClick}
              donateButton={true}
              charityId={charity.charity}
              charityName={charity.name}
              notAcceptingDonation={isPreview || !fundraiser.active}
              showFundraiserButton={false}
            />
          }
          charityId={charity.charity}
        />
        <div className="hidden md:flex flex-col">
          <RecentDonations
            donations={fundraiser.donations}
            totalCount={fundraiser.donation_count}
          />
          <OrganiserCard organiser={fundraiser.organiser} charity={charity} />
        </div>
      </div>

      <div className='flex flex-col md:hidden'>
        <div className="flex flex-col col-span-1 md:col-span-3 gap-y-5 ">
          <FundraiserStory {...props} />
        </div>

        <div className="flex flex-col">
          <RecentDonations
            donations={fundraiser.donations}
            totalCount={fundraiser.donation_count}
          />
          <OrganiserCard organiser={fundraiser.organiser} charity={charity} />
        </div>
        {/* <div className="flex flex-col gap-y-2">
          {generateCommentCard()}
        </div> */}
      </div>

    </div>

    <AboutGivematch />
  </section>
}
