import { type FC, type ReactNode, useEffect, useState } from 'react'

const TEMPID = 'vitol-foundation-ch'

type FeatureAOrB = 'usual' | 'temp'

interface TempCharityFeatureProps {
  usual: ReactNode
  temp?: ReactNode
  charityId?: string
}

export const TempCharityFeature: FC<TempCharityFeatureProps> = ({
  usual,
  temp,
  charityId
}) => {
  const [feature, setFeature] = useState<FeatureAOrB | undefined>(undefined)

  useEffect(() => {
    setFeature(shouldUseTempFeature(charityId) ? 'temp' : 'usual')
  }, [charityId])

  if (!feature) return null
  return <>{feature === 'usual' ? usual : temp}</>
}

export const shouldUseTempFeature = (charityId?: string): boolean => charityId === TEMPID
