import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { CauseType, LandingPageType } from '@givematch/common'
import DonationCard from '~/components/DonationCard'
import GivematchLayout from '~/components/GivematchLayout'
import { type DonationPlan, useDonationPlan } from '~/hooks/useDonationPlan'
import { Navigation } from '~/service/navigation'
import { Tracking } from '~/service/tracking'
import { ProgressSteps } from '~/components/ramadan/ProgressSteps'
import { Experiment, Variant } from '@lib/react-ab-test'
import { ActiveExperiments, DonationAmountTextVariants } from '~/splitTesting/splittesting'

export const DonationAmountPage: FC = () => {
  const { donation, setDonation } = useDonationPlan()
  const navigate = useNavigate()

  const onDonateButtonClick = (amount: number): void => {
    const newDonation: DonationPlan = {
      ...donation,
      amount: amount || 0,
      eventID: '',
      fundraiserTitle: donation.fundraiserTitle,
      fundraiserID: donation.fundraiserID,
      causeType: CauseType.FUNDRAISER_CHARITY,
      charity: donation.charity,
      causeAmounts: {},
      landingPage: LandingPageType.Fundraiser
    }

    // Regular flow
    setDonation(newDonation)
    Tracking.donateFundraiserButton(amount, donation.donationCurrency, true)
    navigate(Navigation.explainer())
  }
  return (
    <GivematchLayout>
      <ProgressSteps stepToActivate={1} numberOfSteps={4} />
      <div
        className="relative sm:w-full h-4 bg-gm-newgreen-50 mt-2"
      />
      <div className="w-full h-full flex flex-col gap-5 justify-center items-center mt-5">
        <h1 className="text-4xl font-semibold text-center">
          <Experiment name={ActiveExperiments.DonationAmountText}>
            <Variant name={DonationAmountTextVariants.giveText}>
              <span>
                How much would you like to give?
              </span>
            </Variant>
            <Variant name={DonationAmountTextVariants.donateText}>
              <span>
                How much would you like to donate?
              </span>
            </Variant>
          </Experiment>
        </h1>
        <DonationCard
          onButtonClick={onDonateButtonClick}
          donateButton={true}
          charityId={donation.charity.charity}
          charityName={donation.charity.name}
          notAcceptingDonation={false}
          showFundraiserButton={false}
        />
      </div>
    </GivematchLayout>
  )
}
