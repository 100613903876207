import { LandingPageType } from '@givematch/common'
import { isMultiCharity } from '@lib/services'

export const getShareMessage = (landingPage: string, charityName: string, fundraiserTitle: string, shareUrl: string, charityId: string): string => {
  charityName = isMultiCharity(charityId) ? 'charity' : charityName
  const shareMessage = `Givematch is doubling my donation to ${fundraiserTitle ? `[${fundraiserTitle}],` : `${charityName} for free,`} but I need you to donate just £1 using the link below to make that happen!\n\nDonate Now!\n\n${shareUrl}\n\nLet's DOUBLE the funds to help even more people. Thanks!`
  if (landingPage === LandingPageType.Ramadan) {
    return `Don’t miss out on doubling your reward this Ramadan! Givematch is doubling my donation to ${isMultiCharity(charityId) ? 'charity' : `${charityName} and others`}, so I double my reward. On Givematch, I help twice the people at no extra cost to me.\n\nDonate on my link below to not miss twice your reward!\n\n${shareUrl}`
  }
  return shareMessage
}

export const getFundraiserShareMessage = (organiserName: string, charityName: string, shareUrl: string, charityId: string): string => {
  charityName = isMultiCharity(charityId) ? 'charity' : charityName
  return `Givematch is doubling my donation to ${organiserName}’s fundraiser for ${charityName}! This means my donation can help twice the people, at no extra cost to me.\n\nYou can donate via my link below to get your donation doubled too!\n\n${shareUrl}`
}

export const tempCharityShareMessage = (title: string, shareUrl: string): string => {
  return `All donations made to [${title}] are being doubled by Vitol. Please consider donating and sharing with your friends and family! Thanks!\n\n${shareUrl}`
}
